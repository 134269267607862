import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 512 512"
    {...props}>
    <path
      d="m368 350.643-112 63-112-63v-66.562l-32-17.778v103.054l144 81 144-81V266.303l-32 17.778v66.562z"
    />
    <path
      d="M256 45.977 32 162.125v27.734L256 314.3l192-106.663V296h32V162.125Zm160 142.831-32 17.777L256 277.7l-128-71.115-32-17.777-22.179-12.322L256 82.023l182.179 94.463Z"
    />
  </svg>
)

export default SvgComponent
