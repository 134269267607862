import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 329.984 329.984"
    style={{
      enableBackground: "new 0 0 329.984 329.984",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M294.992 144.984c0-24.813-20.186-45-45-45s-45 20.187-45 45c0 19.555 12.541 36.227 30 42.42v57.58c0 30.327-24.673 55-55 55s-55-24.673-55-55v-86.509c34.192-6.968 60-37.271 60-73.491V15c0-8.284-6.716-15-15-15h-30c-8.284 0-15 6.716-15 15s6.716 15 15 15h15v54.984c0 24.813-20.187 45-45 45s-45-20.187-45-45V30h15c8.284 0 15-6.716 15-15s-6.716-15-15-15h-30c-8.284 0-15 6.716-15 15v69.984c0 36.219 25.808 66.522 60 73.491v86.509c0 46.869 38.131 85 85 85s85-38.131 85-85v-57.58c17.459-6.192 30-22.865 30-42.42zm-45 15c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.728 15-15 15z" />
  </svg>
)

export default SvgComponent
