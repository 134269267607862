import React from "react";
import AllPagesPDFViewer from "./PDFViewer";
/* This is required only if the project file is located 
inside the app. Otherwise you can use the external link of the pdf file*/
// import Rating from "./Rating";
// import PopUp from "./PopUp"

export default function PDFInfo({ title, pdf }) {
  return (
    <div>
      <div className="PDF">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginInline: "2%",
          }}
        >
          <div>
            {/* <Rating /> */}
          </div>
          <div>
            <a
              href={pdf}
              target="_blank"
              rel="noopener noreferrer"
              className="open-pdf"
            >
              Open PDF in new tab
            </a>
          </div>
        </div>
        <h1 style={{ textAlign: "Center" }}>{title}</h1>
      </div>
      <hr />
      <div>
        <AllPagesPDFViewer pdf={pdf} />
      </div>
    </div>
  );
}
