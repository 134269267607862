import "./App.css";
import React, {useState} from "react";
import Index from "./components/Homepage/Index";

import "./fonts/Alata-Regular.ttf";

function App({ ...props }) {
  const [token, setToken] = useState();

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="wrapper">
        <Index/>
      </div>
    </div>
  );
}

export default App;
