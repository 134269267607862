import * as React from "react"

const SvgComponent = (props) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
        style={{
            cursor: "pointer",

        }}
        {...props}>
        <g data-name="Layer 2">
            <path
                d="m13.41 12 4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"
                data-name="close"
            />
        </g>
    </svg>
)

export default SvgComponent
