import React, { useRef, useState} from "react";
import BodyPart from "./BodyPart";

export default function Body({handleShowMenu }) {
  const group = useRef()
  const [currentHover, setCurrentHover] = useState("")

  const handleOnClick = (e, bodyPart) => {
    handleShowMenu(bodyPart)
  }

  const handleOnPointerEnter = (e, bodyPart) => {
    setCurrentHover(bodyPart)
  }

  const renderBodyPart = (name) => {
    return (
      <BodyPart
        name={name}
        handleOnClick={handleOnClick}
        handleOnPointerEnter={handleOnPointerEnter}
        currentHover={currentHover}
        hoverColor= "rgb(240, 130, 40)"
      />
    )
  }
  return (
    <>
      <group ref={group} dispose={null}>
        {renderBodyPart("Left_Upper_Arm")}
        {renderBodyPart("Right_Upper_Arm")}
        {renderBodyPart("Neck")}
        {renderBodyPart("Left_Foot")}
        {renderBodyPart("Trunk")}
        {renderBodyPart("Right_Foot")}
        {renderBodyPart("Head")}
        {renderBodyPart("Left_Elbow")}
        {renderBodyPart("Left_Forearm")}
        {renderBodyPart("Left_Hand")}
        {renderBodyPart("Right_Forearm")}
        {renderBodyPart("Right_Elbow")}
        {renderBodyPart("Right_Hand")}
        {renderBodyPart("Left_Thigh")}
        {renderBodyPart("Left_Knee")}
        {renderBodyPart("Left_Lower_Leg")}
        {renderBodyPart("Right_Thigh")}
        {renderBodyPart("Right_Knee")}
        {renderBodyPart("Right_Lower_Leg")}
      </group>
    </>
  )
}
