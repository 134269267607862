import { PerspectiveCamera, OrbitControls } from "@react-three/drei";
import "../../App.css";
import React, { useState, Suspense} from "react";
import { Canvas } from "@react-three/fiber";
import Body from "../../assets/body/Body";
import "../../fonts/Alata-Regular.ttf";
import OperationInfo from "../OperationInfo";
import CloseIcon from "../../assets/svgs/CloseIcon";

import PDFInfo from "../PDFInfo";

const Index = () => {
  const [cameraPosition, setCameraPosition] = useState([0, 5, 30]);

  const [showMenu, setShowMenu] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [menuTitleWithUnderscore, setMenuTitle] = useState("");

  const [title, setTitle] = useState("Placeholder");
  const [pdf, setPDF] = useState();

  const menuTitle = menuTitleWithUnderscore.replaceAll("_", " ");

  function handleShowMenu(bodyPart) {
    bodyPart = bodyPart.replace("Right_", "");
    bodyPart = bodyPart.replace("Left_", "");
    setShowMenu(true);
    setMenuTitle(bodyPart);
  }

  // F8B4AE, C54C4C, ED4B3B
  function handleHideMenu() {
    setShowMenu(false);
    setShowPDF(false);
  }

  function handleHidePDF() {
    setShowPDF(false);
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        className="menu"
        style={{
          transform: showMenu ? "translate(0)" : "translate(-100%)",
        }}
      >
        <div className="sticky">
          <div>
            <CloseIcon width={40} height={40} onClick={handleHideMenu} />
          </div>
        </div>
        {showMenu &&
        <OperationInfo
          menuTitle={menuTitle}
          menuTitleWithUnderscore={menuTitleWithUnderscore}
          setTitle={setTitle}
          setShowPDF={setShowPDF}
          setPDF={setPDF}
        />
}
      </div>
      <div
        className="pdf-panel"
        style={{
          transform: showPDF ? "translate(-0%)" : "translate(100%)",
        }}
      >
        <div
          className="Button-hide"
        >
          <CloseIcon width={40} height={40} onClick={handleHidePDF}
 />
        </div>
        <PDFInfo title={title} pdf={pdf}></PDFInfo>
      </div>

      <div style={{ width: "100%" }}>
        <Canvas
          shadows
          dpr={[1, 2]}
          gl={{ alpha: false }}
          camera={{ position: cameraPosition, fov: 45 }}
        >
          <color attach="background" args={["#d9d9d9"]} />
          <ambientLight intensity={0.3} />
          <directionalLight position={[-2, 7, 2]} intensity={2} />
          <PerspectiveCamera position={[0, 100, 0]} />
          <OrbitControls />
          <Suspense fallback="loading body...">
            <Body
              setCameraPosition={setCameraPosition}
              handleShowMenu={handleShowMenu}
            />
          </Suspense>
          {/* <gridHelper args={[20, 10, '#101010', '#050505']} position={[0, 0, 0]} rotation={[Math.PI/2, 0, 0]} />
          <gridHelper args={[20, 10, '#101010', '#050505']} position={[0, 0, 0]} rotation={[0, 0, 0]} /> */}
        </Canvas>
      </div>
    </div>
  );
};

export default Index;
