import React, { useRef, useState, Suspense } from "react";
import { PerspectiveCamera, useGLTF, OrbitControls } from '@react-three/drei'

export default function BodyPart({ name, handleOnClick, handleOnPointerEnter, currentHover, hoverColor = "#ED4B3B" }) {
  const { nodes, materials } = useGLTF('/body-merged.glb')
  return (
    <mesh onClick={e => { handleOnClick(e, name) }} onPointerEnter={e => handleOnPointerEnter(e, name)} geometry={nodes[name].geometry} material={materials['default.002']} position={[0, -10, 0]} rotation={[Math.PI / 2, 0, 0]}>
      {currentHover == name && (
        <meshPhongMaterial color={hoverColor} />
      )}
    </mesh>
  )
}
