import React from "react";
import { useEffect, useState } from "react";
import DifficultyIcon from "../assets/svgs/DifficultyIcon";
import MaterialReqIcon from "../assets/svgs/MaterialReqIcon";

const CalculateDiffDots = ({ number }) => {
  if (number === 1) {
    return (
      <div className="all-dots">
        <div className="rating-dot"></div>
        <div className="rating-dot rating-unfilled"></div>
        <div className="rating-dot rating-unfilled"></div>
      </div>
    );
  }
  if (number === 2) {
    return (
      <div className="all-dots">
        <div className="rating-dot"></div>
        <div className="rating-dot"></div>
        <div className="rating-dot rating-unfilled"></div>
      </div>
    );
  }

  if (number === 3) {
    return (
      <div className="all-dots">
        <div className="rating-dot"></div>
        <div className="rating-dot"></div>
        <div className="rating-dot"></div>
      </div>
    );
  }
};

const OperationCard = ({
  title,
  materialRequirements,
  difficulty,
  rating,
  link,
  linkTitle,
  setTitle,
  setShowPDF,
  setPDF,
}) => {
  const viewPDF = () => {
    setTitle(title);
    setPDF(link);
    setShowPDF(true);
  };

  return (
    <div className="operation-card" onClick={viewPDF}>
      <div className="title">{title}</div>
      <div className="rating">
        <DifficultyIcon className="difficulty-icon" height={25} width={25} />
        <CalculateDiffDots number={difficulty} />
      </div>
      <div className="rating">
        <MaterialReqIcon className="difficulty-icon" height={25} width={25} />
        <CalculateDiffDots number={materialRequirements} />
      </div>
    </div>
  );
};

const Operations = ({
  operations,
  skill,
  equipment,
  setShowPDF,
  setPDF,
  setTitle,
}) => {
  operations = operations.filter((item) => {
    return item.skill <= skill && item.equipment <= equipment ? true : false;
  });

  return (
    <>
      {operations.map((item, key) => {
        return (
          <OperationCard
            key={key}
            title={item.title}
            materialRequirements={item.equipment}
            difficulty={item.skill}
            rating={item.avg_rating}
            link={item.pdf}
            linkTitle={item.title}
            setTitle={setTitle}
            setShowPDF={setShowPDF}
            setPDF={setPDF}
          />
        );
      })}
    </>
  );
};

export default function OperationInfo({
  menuTitle,
  setShowPDF,
  setPDF,
  setTitle,
}) {
  const [operations, setOperations] = useState(null);
  const [equipment, setEquipment] = useState(3);
  const [skill, setSkill] = useState(3);

  
  useEffect(() => {
    const getOperations = async () => {
      const response = await fetch(
        `https://admin.lamba-atlas.com/api/${menuTitle}/operations/`,
        {
          mode: "cors",
        }
      ).then((response) => response.json());
  
      // update the state
      setOperations(response);
    };
    getOperations();
  }, [menuTitle]);

  return (
    <>
      <div className="levels">
        <div>
          <DifficultyIcon className="difficulty" height={20} width={20} /> Skill
          level
        </div>
        <div
          style={{ marginLeft: "5%", display: "flex", alignItems: "center" }}
        >
          <div
            className="rating-dot"
            onClick={() => {
              setSkill(1);
            }}
          />

          {skill >= 2 ? (
            <div
              className="rating-dot"
              onClick={() => {
                setSkill(2);
              }}
            />
          ) : (
            <div
              className="rating-dot rating-unfilled"
              onClick={() => {
                setSkill(2);
              }}
            />
          )}

          {skill >= 3 ? (
            <div
              className="rating-dot"
              onClick={() => {
                setSkill(3);
              }}
            />
          ) : (
            <div
              className="rating-dot rating-unfilled"
              onClick={() => {
                setSkill(3);
              }}
            />
          )}
        </div>
      </div>
      <div className="levels">
        <div>
          <MaterialReqIcon height={20} width={20} /> Equipment
        </div>
        <div
          style={{ marginLeft: "5%", display: "flex", alignItems: "center" }}
        >
          <div
            className="rating-dot"
            onClick={() => {
              setEquipment(1);
            }}
          />
          {equipment >= 2 ? (
            <div
              className="rating-dot"
              onClick={() => {
                setEquipment(2);
              }}
            />
          ) : (
            <div
              className="rating-dot rating-unfilled"
              onClick={() => {
                setEquipment(2);
              }}
            />
          )}
          {equipment >= 3 ? (
            <div
              className="rating-dot"
              onClick={() => {
                setEquipment(3);
              }}
            />
          ) : (
            <div
              className="rating-dot rating-unfilled"
              onClick={() => {
                setEquipment(3);
              }}
            />
          )}
        </div>
      </div>
      <div className="Operation-name">{menuTitle}</div>
      {operations && (
        <Operations
          operations={operations}
          skill={skill}
          equipment={equipment}
          setTitle={setTitle}
          setShowPDF={setShowPDF}
          setPDF={setPDF}
        />
      )}
    </>
  );
}
